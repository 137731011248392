import { assertExportSpecifier } from "@babel/types";
import axios from "axios";
import { Formik } from "formik";
import { useState } from "react";

interface ScenariuProps {
  title: string;
  text: string;
  setValue: (value: string) => void;
}
const Scenariu = ({ title, text, setValue }: ScenariuProps) => {
  const [show, setShow] = useState(false);

  const submitData = (values: any) => {
    axios
      .post("https://tiff.50s.ro/scenariu.php", {
        ...values,
        title,
      })
      .then((res) => {});
  };
  return (
    <div className="relative ">
      <div className="w-11/12 mx-auto mb-1 space-y-0 ">
        <h4>{title}</h4>
        <p>{text}</p>
        <Formik
          initialValues={{ value: "", nume: "", telefon: "", email: "" }}
          onSubmit={submitData}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <textarea
                className="w-full  min-h-[80px] text-[14px] px-4 outline-black resize-none mt-2  bg-transparent border border-black rounded-3xl"
                name="value"
                onChange={(e) => {
                  setValue(`${text} \n ${e.target.value}`);
                  handleChange(e);
                }}
                onBlur={(e) => {
                  setValue(`${text} \n ${e.target.value}`);
                  handleChange(e);
                }}
                value={values.value}
              />

              <div
                className={`${
                  show ? "" : "hidden"
                }  absolute top-0 left-0 w-full h-screen bg-black bg-opacity-20`}
              >
                <div className="bg-[#34303D] w-8/12 mx-auto my-10  py-[20px] rounded-3xl px-10 popup-data">
                  <h4 className=" iq">introdu aici datele tale de contact:</h4>
                  <div>
                    <label>nume</label>
                    <input
                      type={"text"}
                      name="nume"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={values.nume}
                    />
                  </div>
                  <div>
                    <label>telefon</label>
                    <input
                      type={"text"}
                      name="telefon"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={values.telefon}
                    />
                  </div>
                  <div>
                    <label>email</label>
                    <input
                      type={"text"}
                      name="email"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={values.email}
                    />
                  </div>
                  <div className="rules">
                    Apăsând butonul sunteți de acord cu{" "}
                    <a target="_blank" href="/termeni.pdf">
                      termenii și condițiile
                    </a>{" "}
                    activării.
                  </div>
                  <div className="justify-center w-full mb-0">
                    <button
                      type="submit"
                      className=" py-2 text-[16px] px-12 border text-white mx-auto border-white rounded-3xl"
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Scenariu;
