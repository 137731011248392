import axios from "axios";
import { useState } from "react";
import "./App.css";
import Header from "./Header";
import Scenariu from "./Scenariu";
function App() {
  const [v1, setV1] = useState(
    "Pe aceeași masă, patru telefoane bipăie în același timp. Patru mâini se întind simultan. Pe fiecare ecran, același mesaj...<br /><br />"
  );
  const [v2, setV2] = useState("Dintr-o dată, apare un/ o...<br /><br />");
  const [v3, setV3] = useState(
    "Cei 4 au început să pună la cale un plan...<br /><br />"
  );
  const [v4, setV4] = useState(
    "În ultima clipă, cu un efort comun au...<br /><br />"
  );
  const [v5, setV5] = useState(
    "În final, au reușit să ajungă la...<br /><br />"
  );

  const submitData = (values: any) => {
    axios
      .post("https://tiff.50s.ro/scenariu.php", {
        v1,
        v2,
        v3,
        v4,
        v5,
      })
      .then((res) => {
        window.location.reload();
      });
  };
  return (
    <div className="flex p-0 mx-0 App">
      <div className="w-full border-r border-black ">
        <Header />
        <div className="h-screen py-4 overflow-auto ">
          <Scenariu
            title="Introducere"
            setValue={(value) => setV1(`${value}`)}
            key="1"
            text="Pe aceeași masă, patru telefoane bipăie simultan. Pe fiecare ecran, același mesaj..."
          />
          <Scenariu
            title="Intrigă"
            key="2"
            setValue={(value) => setV2(value)}
            text="Dintr-o dată, apare un/ o..."
          />
          <Scenariu
            title="Desfășurarea acțiunii"
            key="3"
            setValue={(value) => setV3(value)}
            text="Cei 4 au început să pună la cale un plan..."
          />
          <Scenariu
            title="Punct culminant"
            key="4"
            setValue={(value) => setV4(value)}
            text="În ultima clipă, cu un efort comun au..."
          />
          <Scenariu
            title="Deznodământ"
            key="5"
            setValue={(value) => setV5(value)}
            text="În final, au reușit să ajungă la..."
          />

          <div>
            <div className="flex justify-center w-full mb-0">
              <button
                onClick={submitData}
                type="button"
                className=" py-2 text-[16px] px-12 border text-black mx-auto border-black rounded-3xl"
              >
                Ok
              </button>
            </div>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
